<script setup lang="ts">
import type { Locale } from 'lc-services/types'

const localePath = useLocalePath()
const { locale } = useI18n<unknown, Locale>()

const props = withDefaults(
  defineProps<{
    house: {
      destination: string
      id: string
      img: string
      name: string
      slug: { fr: string; en: string }
    }
    alt: string
    page: string
    section: string
    width?: 608 | 400
  }>(),
  { width: 400 },
)
defineEmits<{
  'track-featured-houses': [houseId: string]
}>()

const path = computed(() => {
  let path = ''

  if (props.house.slug) {
    path = localePath({
      name: 'house-slug',
      params: { slug: props.house.slug[locale.value] },
    })
  }

  return path
})
</script>

<template>
  <div class="home-house-card">
    <NuxtLink
      :to="path"
      class="home-house-card__img"
      @click="$emit('track-featured-houses', house.id)"
    >
      <BaseNuxtImg
        :alt="alt"
        :src="house.img"
        :width="width"
        height="533"
        loading="lazy"
      />
    </NuxtLink>

    <NuxtLink
      :to="path"
      class="text-lg home-house-card__title"
      @click="$emit('track-featured-houses', house.id)"
    >
      {{ house.name }}
    </NuxtLink>
    <p class="home-house-card__subtitle">
      {{ house.destination }}
    </p>
  </div>
</template>

<style>
.home-house-card {
  @apply flex flex-col items-center;
}

.home-house-card__img {
  height: 448px;
  @apply block w-full;
}

@screen md {
  .home-house-card__img {
    height: 533px;
  }
}

.home-house-card__title {
  @apply text-secondary-500 mt-4;
}
.home-house-card__title:hover {
  @apply text-secondary-500;
}

.home-house-card__subtitle {
  @apply text-lg text-center leading-6 m-0 mt-1;
}
</style>
